<div
  class="tw-bg-background tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center"
>
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-6">
    <mat-spinner />
    <h1 class="tw-text-xl tw-text-text tw-font-bold" i18n>
      Loading application...
    </h1>
  </div>
</div>
