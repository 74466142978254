import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";

@Component({
    selector: "ke-feat-not-found",
    imports: [RouterModule, MatButtonModule],
    templateUrl: "./not-found.component.html"
})
export class NotFoundComponent {}
