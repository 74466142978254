export enum AuroraResource {
  DataAggregator = "accumulators",
  Configuration = "configurations",
  Event = "events",
  Node = "nodes",
}

export enum AdministrationResource {
  // schedule-api
  Job = "job",
  // logging-api
  LoggingApiMessage = "logging-message",
  // meter-control-api
  AllowedFuse = "allowed-fuses",
  FlowInstance = "flow-instances",
  Configuration = "configuration",
  MeterControlApiMessage = "messages",
}

export enum KraftvaktResource {
  // outage-api
  Contact = "contact",
  MessageTemplate = "message-template",
  Outage = "outage",
  Place = "place",
  Shift = "shift",
  Subscriber = "subscriber",
}

export enum StadshubbResource {
  // device-alarm-api
  Alarm = "alarm",
  // device-api
  Model = "model",
  Brand = "brand",
  DeviceCategory = "device-category",
  Channel = "channel",
  Node = "node",
  ConnectivityType = "connectivity-type",
  TransmitProfile = "transmit-profile",
  Unit = "unit",
  // device-coding-api
  Command = "command",
  Converter = "converter",
  Coder = "coder",
  // device-data-api
  ValueSeries = "value-series",
  MessageValue = "message-value",
  Series = "series",
  DeviceDataMessage = "device-data-message",
  // device-flow-api
  Connector = "connector",
  // device-logging-api
  DeviceLoggingMessage = "device-logging-message",
  // device-state-api
  Accumulator = "accumulator",
  Report = "report",
  // integration-data-api
  SendPolicy = "send-policy",
  Product = "product",
  Service = "service",
  IntegrationDataMessage = "integration-data-message",
  Integration = "integration",
  // meter-data-api
  ErrorDescription = "error-description",
}
