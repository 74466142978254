import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationLoaderComponent } from "@kalmarenergi/ui/loader/components/application-loader";
import { firstValueFrom } from "rxjs";
import { IdentityService, UserPermissionsService } from "../../services";
import {
  COLLECT_AUTH_INFO_ROUTE,
  REDIRECT_PARAM,
  getAuthInfo,
  getRedirectPath,
  getRouterNavigationArgs,
} from "../../utils";

@Component({
    selector: "portal-auth-redirect",
    templateUrl: "./redirect.component.html",
    styleUrls: [],
    providers: [],
    imports: [ApplicationLoaderComponent]
})
export class KeAuthRedirectComponent implements OnInit {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private router: Router,
    private identityService: IdentityService,
    private userPermissionsService: UserPermissionsService,
  ) {}

  async ngOnInit(): Promise<void> {
    const redirectPath = getRedirectPath(window.location.search);

    const options = getAuthInfo();
    if (!options) {
      this.collectAuthInfo(redirectPath);
      return;
    }

    let isLoggedIn = false;
    try {
      isLoggedIn = await this.identityService.initialize(options);
    } catch (error) {
      this.collectAuthInfo(redirectPath);
    }

    if (isLoggedIn) {
      this.redirectUserToApplication(redirectPath);
      return;
    }

    const redirectUri = `${this.identityService.getBaseUrl()}auth/redirect?${REDIRECT_PARAM}=${redirectPath}`;
    await this.identityService.login({
      loginHint: options.username,
      locale: this.locale,
      redirectUri,
    });
  }

  async redirectUserToApplication(redirectPath: string) {
    try {
      await firstValueFrom(this.userPermissionsService.syncPermissions());
    } catch (error) {
      console.error("Error syncing permissions in redirect. Error: ", error);
    }

    const { path, queryParams } = getRouterNavigationArgs(redirectPath);
    this.router.navigate([path], { queryParams });
  }

  collectAuthInfo(redirectPath: string) {
    this.router.navigate([COLLECT_AUTH_INFO_ROUTE], {
      queryParams: {
        [REDIRECT_PARAM]: redirectPath,
      },
    });
  }
}
