import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable, Signal, computed, inject, signal } from "@angular/core";
import {
  APP_ENVIRONMENT,
  BYPASS_BASE_INTERCEPTOR,
} from "@kalmarenergi/util/config";
import { Scope } from "@kalmarenergi/util/models/auth";
import { Observable, catchError, forkJoin, map, of, tap } from "rxjs";
import { AdministrationResource } from "../models/resource";
import { UNAUTHORIZED_FEEDBACK, getAuthInfo } from "../utils";

interface UserPermissions {
  [resourceName: string]: Array<Scope>;
}

interface AudienceResourcePermissions {
  resource: string;
  scopes: Scope[];
}

interface AudienceResourcePermissionsError {
  error: string;
  error_description: string;
}

interface AudienceResourcesPermissionsResponse {
  [audience: string]: Array<
    AudienceResourcePermissions | AudienceResourcePermissionsError
  >;
}

/**
 * Manages user permissions by syncing and updating them from the server.
 */
@Injectable({
  providedIn: "root",
})
export class UserPermissionsService {
  private http = inject(HttpClient);
  private environment = inject(APP_ENVIRONMENT);

  private _userPermissions = signal<UserPermissions>({});
  public userPermissions = this._userPermissions.asReadonly();

  public initialSync = false;
  public isSyncing = signal(false);

  /**
   * Resources that have potential name clashed, for example if a resource is named "message" in multiple APIs.
   */
  private resourceNameClashes: string[] = ["message", "category"];
  private resourceNamePreserveSpelling: string[] = [
    AdministrationResource.AllowedFuse,
    AdministrationResource.FlowInstance,
  ];

  /**
   * Updates the user permissions with the provided permissions.
   * @param permissions The new permissions to update with.
   */
  private updateUserPermissions(permissions: UserPermissions) {
    this._userPermissions.update((prevPermissions) => {
      // Remove permissions for resources not included in the new permissions
      const prevResources = Object.keys(prevPermissions);
      for (let resource in prevResources) {
        if (!Object.keys(permissions).includes(resource)) {
          delete prevResources[resource];
        }
      }

      // Merge new permissions with existing ones
      return {
        ...prevPermissions,
        ...permissions,
      };
    });
  }

  /**
   * Resets all user permissions.
   */
  public resetPermissions() {
    this._userPermissions.set({});
  }

  /**
   * Syncs the user permissions with the server.
   * @param setIsSyncing Indicates whether to set the syncing state.
   * @returns An observable that emits the updated permissions.
   */
  public syncPermissions(setIsSyncing = true) {
    this.initialSync = true;
    const realm = getAuthInfo()?.realm;

    if (!realm) {
      throw new Error("Couldn't sync permissions. No realm found.");
    }

    if (setIsSyncing) {
      this.isSyncing.set(true);
    }

    const audiences = this.environment.audiences;

    const requests = audiences.map((audience) =>
      this.fetchAudienceResourcesPermissions(realm, audience),
    );

    /**
     * ForkJoin is used to make multiple requests in parallel and wait for all of them to complete.
     * This is more efficient than making requests sequentially.
     * The requests are made for each audience to get the permissions for all resources.
     *
     * Errors are caught and handled in the fetchAudienceResourcesPermissions method and is not needed here.
     */
    return forkJoin(requests).pipe(
      tap((allPermissions) => {
        // All permissions for all resources of all audiences
        const userPermissions = this.getUserPermissions(allPermissions);
        this.updateUserPermissions(userPermissions);
      }),
      tap(() => {
        this.isSyncing.set(false);
      }),
    );
  }

  /**
   * Returns a signal that indicates whether the user has the specified permissions.
   * @param permissions The permissions to check.
   * @param operator The operator to use for combining multiple permissions.
   *        - "and": All permissions must be satisfied.
   *        - "or": At least one permission must be satisfied.
   * @returns A signal that indicates whether the user has the specified permissions.
   */
  public userHasPermission = (
    permissions: [string, Scope] | [string, Scope][],
    operator: "and" | "or" = "and",
  ): Signal<boolean> =>
    computed(() => {
      if (typeof permissions[0] === "string") {
        return this.userPermissions()[permissions[0] as string]?.includes(
          permissions[1] as Scope,
        );
      }

      if (operator === "and") {
        return (permissions as Array<[string, Scope]>).every(
          ([resourceName, scope]) =>
            this.userPermissions()[resourceName]?.includes(scope),
        );
      } else if (operator === "or") {
        return (permissions as Array<[string, Scope]>).some(
          ([resourceName, scope]) =>
            this.userPermissions()[resourceName]?.includes(scope),
        );
      }

      throw new Error("Invalid operator specified.");
    });

  /**
   * Executes the specified feature if the user has the specified permissions.
   * @param feature The feature to execute.
   * @param permissions The permissions to check.
   * @returns The result of the feature if the user has the specified permissions.
   * @throws An error if the user doesn't have the specified permissions.
   * @example
   * ```ts
   * executeIfPermitted(() => console.log("Feature executed"), ["shift", "create"]);
   * ```
   * @example
   * ```ts
   * executeIfPermitted(() => console.log("Feature executed"), [
   *  ["shift", "create"],
   *  ["shift", "update"],
   * ]);
   * ```
   *
   */
  executeIfPermitted(
    feature: () => any,
    permissions: [string, Scope] | Array<[string, Scope]>,
    operator: "and" | "or" = "and",
  ): void {
    let authorized = false;

    authorized = this.userHasPermission(permissions, operator)();

    if (!authorized) {
      window.alert(UNAUTHORIZED_FEEDBACK);
      return;
    }

    feature();
  }

  /**
   * Extracts and consolidates user permissions from a list of audience resource permissions.
   *
   * @param aggregatedPermissionsResponses - An array of audience resource permissions responses.
   * @returns An object representing the consolidated user permissions.
   */
  private getUserPermissions(
    aggregatedPermissionsResponses: AudienceResourcesPermissionsResponse[],
  ): UserPermissions {
    const permissions: UserPermissions = {};

    aggregatedPermissionsResponses.forEach((audiencePermissions) => {
      const firstResource = Object.values(audiencePermissions)[0][0];
      if ("error" in firstResource) {
        return;
      }

      Object.values(audiencePermissions)
        .flat()
        .forEach((resourcePermission) => {
          const { resource, scopes } =
            resourcePermission as AudienceResourcePermissions;

          const resourceName = this.getResourceName(
            resource,
            Object.keys(audiencePermissions)[0],
          );

          permissions[resourceName] = scopes;
        });
    });

    return permissions;
  }

  /**
   * Returns the resource name based on the initial resource name and audience.
   * If the initial resource name clashes with existing resource names, it appends the audience to the resource name.
   * @param initialResourceName - The initial resource name.
   * @param audience - The audience for the resource.
   * @returns The resource name.
   */
  private getResourceName(
    initialResourceName: string,
    audience: string,
  ): string {
    if (this.resourceNamePreserveSpelling.includes(initialResourceName)) {
      return initialResourceName;
    }

    let resourceName = initialResourceName.replace("_", "-");

    if (this.resourceNameClashes.includes(resourceName)) {
      resourceName = `${audience.replace("-api", "")}-${resourceName}`;
    }

    return resourceName;
  }

  /**
   * Fetches the permissions for the specified audience resources.
   * @param realm The realm for the audience.
   * @param audience The audience for the resource.
   * @returns An observable that emits the permissions for the audience resources.
   */
  private fetchAudienceResourcesPermissions(
    realm: string,
    audience: string,
  ): Observable<AudienceResourcesPermissionsResponse> {
    const url = `${
      this.environment.loginUrl
    }realms/${realm!}/protocol/openid-connect/token`;

    const requestBody = new HttpParams({
      fromObject: {
        grant_type: "urn:ietf:params:oauth:grant-type:uma-ticket",
        response_mode: "permissions",
        audience,
      },
    });

    const requestHeaders = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    const context = new HttpContext().set(BYPASS_BASE_INTERCEPTOR, true);

    return this.http
      .post<Array<{ rsid: string; rsname: string; scopes: Scope[] }>>(
        url,
        requestBody,
        {
          context,
          headers: requestHeaders,
        },
      )
      .pipe(
        map((response) => ({
          [audience]: response.map((resource) => ({
            resource: resource.rsname,
            scopes: resource.scopes,
          })),
        })),
        catchError((errorResponse) => {
          return this.handleAudiencePermissionsResourcesErrorResponse(
            audience,
            errorResponse,
          );
        }),
      );
  }

  /**
   * Handles the error response from the server.
   * @param audience The audience for the resource.
   * @param errorResponse The error response from the server.
   * @returns The permissions response with the error.
   */
  private handleAudiencePermissionsResourcesErrorResponse(
    audience: string,
    errorResponse: HttpErrorResponse,
  ): Observable<AudienceResourcesPermissionsResponse> {
    const error =
      errorResponse instanceof HttpErrorResponse
        ? errorResponse.error?.error || "unknown_error"
        : "unknown_error";

    const errorDescription =
      errorResponse instanceof HttpErrorResponse
        ? errorResponse.error?.error_description ||
          "An unknown error occurred while fetching permissions"
        : "An unknown error occurred while fetching permissions";

    return of({
      [audience]: [
        {
          error,
          error_description: errorDescription,
        },
      ],
    });
  }
}
