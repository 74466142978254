import { CommonModule } from "@angular/common";
import { Component, input, Input } from "@angular/core";
import { Alert, AlertType } from "@kalmarenergi/util/models/alert";

@Component({
    selector: "ke-ui-alert",
    imports: [CommonModule],
    templateUrl: "./alert.component.html",
    styleUrls: ["./alert.component.scss"]
})
export class AlertComponent {
  message = "";
  className = "alert-error";

  class = input<string>("");

  @Input()
  set alert(alert: Alert | null) {
    if (!alert) {
      this.message = "";
      return;
    }
    this.show(alert);
  }

  /**
   * Show error message
   */
  error(message: string): void {
    this.show({
      message,
      type: AlertType.error,
    });
  }

  /**
   * Show success message
   */
  success(message: string): void {
    this.show({
      message,
      type: AlertType.success,
    });
  }

  /**
   * Show info message
   */
  info(message: string): void {
    this.show({
      message,
      type: AlertType.info,
    });
  }

  /**
   * Show warning message
   */
  warning(message: string): void {
    this.show({
      message,
      type: AlertType.warning,
    });
  }

  /**
   * Show alert
   */
  show(alert: Alert): void {
    this.message = alert.message;
    switch (alert.type) {
      case AlertType.error:
        this.className = "alert-error";
        break;
      case AlertType.success:
        this.className = "alert-success";
        break;
      case AlertType.info:
        this.className = "alert-info";
        break;
      case AlertType.warning:
        this.className = "alert-warning";
        break;
    }
  }
}
